var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ListPage',{attrs:{"title":"Użytkownicy systemowi","data":_vm.data,"is-busy":_vm.isBusy,"filters":{},"table-params":[
                  {label: '#', field: 'id', sortable: true, align:'right'},
                  {label: 'Imię', field: 'punt_charter_client_first_name', sortable: true, align:'left'},
                  {label: 'Nazwisko', field: 'punt_charter_client_first_name', sortable: true, align:'left'},
                  {label: 'Email', field: 'punt_charter_client_email', sortable: true, align:'left'},
                  {label: 'Wysłane', field: 'poll_sent_date', sortable: true, align:'left'},
                  {label: 'Wypełnione', field: 'poll_received_date', sortable: true, align:'left'},
                  {label: '', field: null, sortable: false, align:'right', slotComponent:{
                        name: 'doc_type',
                        props: {item: Object},
                        render: function (createElement) {
                        var that = this;
                            if (that.item.poll_received_date || that.item.poll_sent_count > 1) {
                                return null;
                            }
                            return createElement('button', {
                                class: {btn: true, 'btn-sm': true, 'btn-success': true, 'btn-info': false},
                                on: { click(event) {event.stopPropagation(); _vm.onClickSend(that.item)}}} , this.item.poll_sent_date === null ? 'wyślij' : 'wyślij ponownie')
}
                    }},
              ],"load-action-name":_vm.TYPES.POLLS_A_LOAD,"adding-permission-name":_vm.PRIVILEGES.PRIVILEGE_POLLS}})
}
var staticRenderFns = []

export { render, staticRenderFns }