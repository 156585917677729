<template>
    <ListPage title="Użytkownicy systemowi"
              :data="data"
              :is-busy="isBusy"
              :filters="{}"
              :table-params="[
                  {label: '#', field: 'id', sortable: true, align:'right'},
                  {label: 'Imię', field: 'punt_charter_client_first_name', sortable: true, align:'left'},
                  {label: 'Nazwisko', field: 'punt_charter_client_first_name', sortable: true, align:'left'},
                  {label: 'Email', field: 'punt_charter_client_email', sortable: true, align:'left'},
                  {label: 'Wysłane', field: 'poll_sent_date', sortable: true, align:'left'},
                  {label: 'Wypełnione', field: 'poll_received_date', sortable: true, align:'left'},
                  {label: '', field: null, sortable: false, align:'right', slotComponent:{
                        name: 'doc_type',
                        props: {item: Object},
                        render: function (createElement) {
                        var that = this;
                            if (that.item.poll_received_date || that.item.poll_sent_count > 1) {
                                return null;
                            }
                            return createElement('button', {
                                class: {btn: true, 'btn-sm': true, 'btn-success': true, 'btn-info': false},
                                on: { click(event) {event.stopPropagation(); onClickSend(that.item)}}} , this.item.poll_sent_date === null ? 'wyślij' : 'wyślij ponownie')
}
                    }},
              ]"
              :load-action-name="TYPES.POLLS_A_LOAD"
              :adding-permission-name="PRIVILEGES.PRIVILEGE_POLLS"
    >
    </ListPage>
</template>
<script>
import ListPage from "@/pages/Common/ListPage";
import * as TYPES from '@/store/types';
import * as PRIVILEGES from '@/config/Privileges'

import {mapGetters} from 'vuex'
import Vue from "vue";
import TestDialog from "@/pages/Common/TestDialog";
export default {
    name: 'SystemUsersListPage',
    components: {ListPage},
    data() {
        return {
            TYPES,
            PRIVILEGES
        }
    },
    computed: {
        ...mapGetters({
            data: [TYPES.POLLS_G_GET_ITEMS],
            isBusy: [TYPES.POLLS_G_GET_IS_BUSY]
        })
    },
    methods: {
        onClose() {
            console.log('panel closed');
        },
        onClickSend(poll) {
            this.send(poll.id)
        },
        send(poll_id) {
            this.$store.dispatch(TYPES.POLLS_A_SEND, {id: poll_id}).then((response) => {
                if (response.data === 'OK') {
                    this.$store.dispatch(TYPES.POLLS_A_LOAD);
                }
            })
        }
    }
}
</script>